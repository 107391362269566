import { Routes } from '@angular/router';
import { UserPreferenceResolver } from '@mri-platform/import-export/common-state';
import { AppRoutes } from '@mri-platform/import-export/core';
import { ShellComponent } from '@mri-platform/import-export/shell';
import {
  UserEnrollmentCallbackGuard,
  UserEnrollmentGuard
} from '@mri-platform/import-export/user-enrollment-configuration';
import {
  ClientsResolver,
  ClientSwitchListPageComponent,
  UnhandledErrorPageComponent
} from '@mri-platform/shared/shell';
import { AuthGuard } from '@mri/angular-wfe-proxy-oidc';
import { UserEnrollmentPageComponent } from './containers/user-enrollment-page.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: ShellComponent,
    resolve: {
      clients: ClientsResolver,
      userPreference: UserPreferenceResolver
    },
    children: [
      AppRoutes.Enroll.applyTo({
        canActivate: [UserEnrollmentGuard],
        component: UserEnrollmentPageComponent
      }),
      AppRoutes.Callback.applyTo({
        canActivate: [UserEnrollmentCallbackGuard],
        component: UserEnrollmentPageComponent
      }),
      {
        path: AppRoutes.ImportExportJobs.path,
        loadChildren: () => import('@mri-platform/import-export/jobs').then(module => module.ImportExportJobsModule)
      },
      {
        path: AppRoutes.PlatformManagement.path,
        loadChildren: () =>
          import('@mri-platform/import-export/platforms-management').then(module => module.PlatformsManagementModule)
      },
      {
        path: AppRoutes.ClientManagement.path,
        loadChildren: () =>
          import('@mri-platform/import-export/client-management').then(module => module.ClientManagementModule)
      },
      {
        path: AppRoutes.MappingConnections.path,
        loadChildren: () =>
          import('@mri-platform/import-export/connection-management').then(module => module.ConnectionManagementModule)
      },
      {
        path: AppRoutes.UserSecuritySettings.path,
        loadChildren: () => import('@mri-platform/import-export/admin').then(module => module.AdminModule)
      },
      {
        path: AppRoutes.MappingManager.path,
        loadChildren: () =>
          import('@mri-platform/import-export/mapping-management').then(module => module.MappingManagementModule)
      },
      {
        path: AppRoutes.RecentImportExport.path,
        loadChildren: () =>
          import('@mri-platform/import-export/recent-job-logs').then(module => module.RecentJobsModule)
      },
      AppRoutes.Error.applyTo({ component: UnhandledErrorPageComponent }),
      AppRoutes.SwitchClient.applyTo({
        component: ClientSwitchListPageComponent,
        outlet: 'drawer',
        resolve: {
          clients: ClientsResolver
        }
      }),
      { path: '', redirectTo: AppRoutes.RecentImportExport.path, pathMatch: 'full' }
    ]
  }
];
