<mri-shared-shell [headerLogo]="headerLogo">
  @if (vm$ | async; as vm) {
    <mri-shared-page-footer
      pageFooter
      [user]="vm.userInfo"
      [currentClientId]="vm.currentClientId"
      [isEnableSwitchClient]="vm.isOperationalUser"
      [additionalOptions]="[enrollmentMenuItem, userPreferenceMenuItem]"
      (handleLogout)="handleLogout()"
      (openClientDrawer)="openClientDrawer()"
    >
    </mri-shared-page-footer>
  }
</mri-shared-shell>
<div kendoDialogContainer></div>
<!-- we are using vm two time here, we will have to think of a better approach -->
@if (vm$ | async; as vm) {
  <mri-ie-session-timeout-dialog
    [show]="vm.showSessionTimeoutDialog"
    (action)="sessionTimeoutDialogAction($event)"
  ></mri-ie-session-timeout-dialog>
}
