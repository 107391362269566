<!--
Breaking these into further components (icon component, item component, etc) breaks some level of the expected layers from the design system.
-->
@if (vm$ | async; as vm) {
  @if (vm.showWidget) {
    <div class="menu-box">
      <div class="widget">
        <ag-widget
          [ApiHost]="this.apiHost"
          [MriClientId]="vm.clientId"
          [debugger]="detailedErrorMessagesWidget"
        ></ag-widget>
      </div>
      <div class="admin" [ngClass]="{ expanded: vm.isNavExpanded, 'logo-text': !vm.headerLogo?.url }">
        @if (vm.headerLogo?.url) {
          <img [src]="vm.headerLogo.url" [alt]="vm.headerLogo.text" />
        } @else {
          <p>{{ vm.headerLogo.text }}</p>
        }
      </div>
    </div>
  }
  <div [ngClass]="{ expanded: vm.isNavExpanded, 'menu-divider': vm.showWidget }"></div>
  @for (item of vm.menuItems; track $index) {
    @let askAgoraVisibleCheck = $any(item).isAskAgora && vm.showAskAiWidget;

    @if (askAgoraVisibleCheck) {
      <ask-ai
        [showIcon]="false"
        [apiHost]="$any(item).baseApiUrl"
        [assetSource]="$any(item).assetSource"
        [clientId]="vm.clientId"
        [widgetOffset]="vm.isNavExpanded ? 200 : 60"
        [isActivated]="item === vm.activeItem"
        (popupClose)="onAskAgoraClosed(vm.activeItem)"
      ></ask-ai>
    }
    <a
      class="navMenuLink {{ item.cssClass }}"
      [title]="item.title"
      [attr.aria-label]="item.title"
      (click)="clickIcon(item)"
      [ngClass]="{
        active: item !== vm.activePage && item === vm.activeItem,
        activePage: item === vm.activePage,
        expanded: vm.isNavExpanded
      }"
    >
      @if (item !== vm.activePage && item === vm.activeItem) {
        <div class="indicator"></div>
      }
      @if (askAgoraVisibleCheck) {
        <ask-ai-sparkle-button [ngClass]="{ active: item === vm.activeItem }" class="mri-icon__svg navMenuSvg">
        </ask-ai-sparkle-button>
      } @else {
        <svg
          [ngClass]="{
            active: item !== vm.activePage && item === vm.activeItem,
            activePage: item === vm.activePage
          }"
          class="mri-icon__svg navMenuSvg"
          viewBox="0 0 25 40"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use [attr.href]="itemHref(item)"></use>
        </svg>
      }
      <div [hidden]="!vm.isNavExpanded" class="nav-menu-text">
        {{ item.title }}
      </div>
    </a>
  }
  <a
    kendoDialogContainer
    class="navMenuLink logout-div nav-menu-mri-icon-anchor"
    [ngClass]="{ expanded: vm.isNavExpanded }"
    (click)="logout()"
    title="Sign out"
    attr.aria-label="Sign out"
  >
    <div class="mri-icon-container">
      <mri-icon icon="logout" class="mri-icon__svg nav-menu-mri-icon"></mri-icon>
    </div>
    <div class="nav-menu-text manual-button" [hidden]="!vm.isNavExpanded">Sign Out</div>
  </a>
  <div class="link-container">
    <a class="chevron-expand-link" (click)="toggleNavExpansion()">
      <div class="chevron-expand">
        @if (vm.isNavExpanded) {
          <mri-icon icon="chevron-left" class="icon-expand chevron-left"></mri-icon>
        } @else {
          <mri-icon icon="chevron-right" class="icon-expand chevron-right"></mri-icon>
        }
      </div>
    </a>
  </div>
}
